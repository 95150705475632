canvas {
  width: 100%;
  height: 100vh;
  z-index: 100;
}
.loading {
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.loading-bar-container {
  position: absolute!important;
  top: 50vh;
  width: 100px;
  height: 32px;
  background: #272727;
}

.loading-bar {
  height: 32px;
  background: #9d4008;
}
#CanvasSection {
//background:linear-gradient(0deg, rgb(39, 39, 39, 1) 5%, rgba(255, 255, 255, 0) 100%), url("../../../assets/png/sectionTwoBackground.jpg") no-repeat 50% 50%

  //background-repeat: no-repeat;
}
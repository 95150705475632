

$breakpoint_xxl: 1367px;
$breakpoint_xl: 1024px;
$breakpoint_md: 767px;
$breakpoint_sm: 576px;
#app { height: 100vh }
html,
body {
  position: relative;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  height: 100%;
}

.swiper {
  .mobileSwiper {
    width: 100%;
    height: 50%;
    background: none;
    .swiper-slide {
      font-size: 18px;
      color: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 20px;
    }
  }

}
.swiper {
  width: 100%;
  height: 100vh;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-image: url("./123.jpeg");
  //filter: grayscale(20%);
  opacity: 40%;
  background-position: center;
}

.swiper-slide .title {
  text-align: center;
  width: 80%;
  font-size: 1.5em;
  font-weight: 300;
}
.select_form {
  width: 80%;
}
.swiper-slide .subtitle {
  width: 80%;
  text-align: center;
  font-size: 1.2em;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

* {
  box-sizing: border-box;
}

header {
  position: fixed;
  top: 0;
  .flex_header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex_content {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    .mainContent {
      display: flex;
      flex-direction: column;
      background: rgba(19, 22, 23, 0.95);
      width: 400px;
      border-radius: 25px;
      height: 500px;
      margin: 25px;
      text-align: center;
      align-items: center;
      justify-content: flex-start;
      p {
        width: 90%;
        margin: 0 auto;
        font-size: 1.3em;
      }
      img {
        margin-top: 70px;
        margin-bottom:55px ;
      }
    }

  }
  display: flex;
  .logo {
    width: 75px;
  }
  flex-direction: row;
  width: 95%;
  margin: 20px auto;
  align-items: center;
  justify-content: center;
  //background: rgba(10, 9, 14, 0.22);
  h1 {
    font-size: 2.5em;
  }
  h2 {
    width: 70%;
    text-align: center;
    padding: 15px;
    font-size: 1.5em;
    border-radius: 25px;

    //color: #efb039;
    //margin-bottom: 25px;
    margin: 25px auto;
  }
}
.first_slide {
  padding: 0;
}
.intl-tel-input.allow-dropdown.separate-dial-code input, .intl-tel-input.allow-dropdown.separate-dial-code input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code input[type=tel] {
  padding-left: 92px!important;
}
.block_first {
  border-top: #E89700 1px solid;
  border-bottom: #E89700 1px solid;

  height: 50vh;
  width: 70vw;
  position: absolute;
  transition: all 600ms ease-out;
}
.swipe_wrapper_first {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 15px;
  //padding: 110px 290px;
  transition: all 600ms ease-out;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(10, 9, 14, 0.22);
  //position: relative;
  //top: 100px;
  margin: 0 auto;

}
.intl-tel-input{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  //padding: 0 10px;
  border-radius: 5px;
  margin: 10px 20px;

  transition: all 200ms ease-in-out;
  outline: none;
  input {
    display: flex!important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 0 10px 10px;
    border-radius: 5px;
    //width: 240px;
    font-family: 'Oxanium', sans-serif;
    transition: all 200ms ease-in-out;
    outline: none;
  }

  .selected-dial-code {
    font-size: 11px;
    align-items: center;
    justify-content: center;
    width: 37.2px!important;
    padding-left: 5px!important;
    color: black;
    pointer-events: none;
  }
  .country-list {
    color: black;
    font-size: 0.7em;
    .country-name {
      color: black;
    }
  }
  .selected-flag {
    display: flex!important;
    align-content: center;
    justify-content: center;
    position: relative;
    padding: 7px;
    width: 86px!important;
    cursor: pointer;
  }
  .flag-container {
    .arrow {
      color: black;
    }
  }

}
.swipe_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(19 22 23);
  border-radius: 15px;
  padding: 30px;
  flex-direction: column;
  -webkit-backdrop-filter: grayscale(5px);
  backdrop-filter: grayscale(5px);
  opacity: 95%;
  width: 50%;
  top: 10%;
  height: 645px;
  margin: 0 auto;
  position: relative;
  .subtitle {
    padding-top: 20px;
  }
  .confirm_button {
    padding:20px 30px;
    font-weight: bold;
    font-size: 1em;
    border-radius: 10px;
    transition: all 200ms ease-in-out;
    background: #5635ff;
    &:hover {
      background: #3f21c0;
      color: #ffffff;
      cursor: pointer;
    }

  }
button:disabled {
  background: #9a9a9a;
  &:hover {
    background: #9a9a9a;
    color: #6b6a6b;
  }

}
  .flex {
    display: flex;
    flex-wrap: wrap;
    margin: 50px auto;
    justify-content: center;
    width: 90%;
    .intl-tel-input{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      //padding: 0 10px;
      border-radius: 5px;
      margin: 10px 20px;

      transition: all 200ms ease-in-out;
      outline: none;
      input {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 10px 0 10px 10px;
        border-radius: 5px;
        font-family: 'Oxanium', sans-serif;
        transition: all 200ms ease-in-out;
        outline: none;
      }
      .selected-dial-code {
        font-size: 11px;
        align-items: center;
        justify-content: center;
        padding-left: 5px!important;
        color: black;
        pointer-events: none;
      }
      .country-list {
        color: black;
        font-size: 0.7em;
        .country-name {
          color: black;
        }
      }

    }
    .iti-mobile {
      .intl-tel-input{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        //padding: 0 10px;
        border-radius: 5px;
        margin: 10px 20px;

        transition: all 200ms ease-in-out;
        outline: none;
        input {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 10px 0 10px 10px;
          border-radius: 5px;
          font-family: 'Oxanium', sans-serif;
          transition: all 200ms ease-in-out;
          outline: none;
        }
        .selected-dial-code {
          font-size: 11px;
          align-items: center;
          justify-content: center;
          padding-left: 5px!important;
          color: black;
          pointer-events: none;
        }
        .country-list {
          color: black;
          font-size: 0.7em;
          .country-name {
            color: black;
          }
        }

      }
    }

    .input {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 10px 0 10px 10px;
      border-radius: 5px;
      margin: 10px 20px;
      font-family: 'Oxanium', sans-serif;
      width: 250px;
      transition: all 200ms ease-in-out;
      outline: none;
    }
    .select_items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 210px;
      //height: 50px;
      border: #959490 solid 1px;
      border-radius: 5px;
      padding: 10px 0;
      margin: 10px 20px;
      transition: all 200ms ease-in-out;
      p {
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
      &:hover{
        background: #ffffff;
        color: #171717;
      }
      &::placeholder {
        padding: 20px;
      }


    }
  }
}
.next-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 170px;
  height: 65px;
  bottom: 7%;
  right: 875px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2em;
  background-color: #5635ff;
  transition: all 100ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3f21c0;

  }
}
.prev {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100px;
  position: absolute;
  height: 50px;
  bottom: 10%;
  left: 95px;
  color: #171717;
  font-weight: bold;
  font-size: 1.3em;
  background-color: #E89700;
  transition: all 100ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #ffb326;

  }
}
.main-header {
  //  position: relative;
  //  display: flex;
  //  flex-direction: column;
  //  justify-content: space-between;
  //
  //  &:after {
  //    content: "";
  //    position: absolute;
  //    height: calc(var(--index) * 1);
  //    bottom: calc(var(--index) * -0.5);
  //    z-index: 100;
  //    transform: scaleX(1.5);
  //    width: 100%;
  //    background: linear-gradient(0deg, rgb(18 14 14) 87%, rgb(0 0 0 / 1) 100%);
  //    background-size: cover;
  //    filter: blur(6px);
  //    -webkit-backdrop-filter: blur(1.5);
  //    background-repeat: no-repeat;
  //    background-position: center;
  //  }
  //  .layers {
  //    height: 100vh;
  //    display: flex;
  //    flex-direction: column;
  //    align-items: flex-end;
  //    justify-content: center;
  //    text-align: center;
  //    //overflow: hidden;
  //    position: relative;
  //    .greet {
  //      margin: 0 auto;
  //    }
  //    .layer {
  //      height: 100%;
  //      width: 100%;
  //      background-size: cover;
  //      background-position: center;
  //      position: absolute;
  //      z-index: 2;
  //      transition: var(--transition);
  //    }
  //    .layer__base {
  //      //background-image: url("../../../assets/png/stayBackgroundPartTwo.png");
  //      background-position: 0 calc(var(--index)/15);
  //      background-repeat: no-repeat;
  //      z-index: 0;
  //    }
  //    .layer__middle {
  //      //background-image: url("../../../assets/png/strayFrontSide.png");
  //      background-size: auto;
  //      background-repeat: no-repeat;
  //      bottom: 0;
  //      //transform: translateY(calc(var(--index)*3.8));
  //    }
  //    .layer__header {
  //      display: flex;
  //      flex-direction: column;
  //      align-items: flex-end;
  //      will-change: transform;
  //      font-weight: 900;
  //      padding-bottom: calc(var(--index) * 10 );
  //      transition: var(--transition);
  //      //transform: translateY();
  //      z-index: 1;
  //      .layer__caption {
  //        font-size: calc(var(--index) * 4);
  //        letter-spacing: calc(var(--index)/2.5);
  //        text-shadow: 0 0 20px #40abfa;
  //      }
  //      .layer__tittle {
  //        font-size: calc(var(--index)/1.25);
  //        font-weight: 600;
  //      }
  //    }
  //
  //  }
}

@media (max-width: $breakpoint_xxl) {
  header{
    .flex_header {
      position: relative;
      left: -25px;
    }
    h1 {
      font-size: 2em;
    }
    h2 {
      margin: 15px auto 5px auto;
    }
    .logo {
      width: 70px;
      margin-right: 25px;
    }
    .flex_content {

      .mainContent {
        background: rgba(19, 22, 23, 0.95);
        width: 300px;
        border-radius: 25px;
        height: 400px;
        margin: 25px;
        text-align: center;
        align-items: center;
        justify-content: space-around;
        img {
          width: 152px;
          height: 152px;
          margin: 25px 0 0 0;
        }
        p {
          font-size: 1em;
        }
      }
    }
  }
  .swipe_wrapper {
    height: 85%;
    width: 80%;
    .flex {
      width: 100%;
      margin: 30px auto;
      .select_items {
        width: 190px;
        margin: 15px;
        p {
          font-size: 0.9em;
        }
      }
    }
  }
  .select_form {
    width: 90%;
  }
  .swiper-slide {
    .title {
      font-size: 1.6em;
    }
  }
}
@media (max-width: $breakpoint_xl) {
  .swipe_wrapper {
    width: 80%;
  }
  .swiper-slide {
    .title {
      font-size: 1.2em;
    }
    .subtitle {
      font-size: 1em;
    }
  }
}
@media (max-width: $breakpoint_md) {
  header{

    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.2em;
      margin: 15px auto 5px auto;
    }
    .logo {
      width: 70px;
      margin-right: 25px;
    }
    .flex_content {

      .mainContent {
        background: rgba(19, 22, 23, 0.95);
        width: 300px;
        border-radius: 25px;
        height: 400px;
        margin: 25px;
        text-align: center;
        align-items: center;
        justify-content: space-around;
        img {
          width: 152px;
          height: 152px;
          margin: 25px 0 0 0;
        }
        p {
          font-size: 1em;
        }
      }
    }
  }
  .swipe_wrapper {
    width: 80%;
    .flex {
      .select_items {
        p {
          font-size: 0.7em;
        }
      }
    }
    .confirm_button {
      font-size: 1em;
    }
  }
  .swiper-slide {
    .title {
      font-size: 1em;
    }
    .subtitle {
      font-size: 0.8em;
    }
  }
}
@media (max-width: $breakpoint_sm) {
  header{
    height: 10vh;
    display: flex;
    width: 100%;
    right: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    .flex_header {
      position: relative;
      left: -30px;
    }
    h1 {
      font-size: 1em;
    }
    h2 {
      width: 90%;
      font-size: 1em;
      margin: 5px auto 0 auto;
      padding: 0;
    }
    .logo {
      width: 45px;
      margin-right: 10px;
    }

  }
  .swiper {
    .mobileSwiper {
      height: 85%;
      .mainContent {
        background: rgba(19, 22, 23, 0.95);
        width: 90%;
        border-radius: 25px;
        //height: 400px;
        margin: 25px;
        text-align: center;
        align-items: center;
        justify-content: space-around;

        p {
          font-size: 1em;
        }
      }
      .swipe_wrapper {
        height: 85%;
        width: 100%;
        padding: 30px;
      }
    }
  }
  .swipe_wrapper {
    height: 85%;
    width: 100%;
    padding: 15px 10px;
    img {
      width: 135px;
      height: 135px;
      margin:0 0 15px 0;
    }
    p {
      margin-top: 15px;
      text-align: center;
      font-size: 1.2em;
    }
    .flex {
      width: 100%;
      margin: 30px auto;
      .select_items {
        width: 225px;
        margin: 10px;
        p {
          font-size: 0.9em;
        }
      }
      .input {
        margin: 10px 0;
      }
    }
  }
  .select_form {
    width: 90%;
  }
  .swiper-slide {
    padding: 20px;
    .title {
      font-size: .9em;
    }
    .subtitle {
      width: 90%;
      font-size: .8em;
    }
  }
}
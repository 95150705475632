* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --index: calc(1vw + 1vh);
  --text: #fff9e9;
  --second-text: #302319;
  --background: #272727;
  --scrollTop:0;
  --transition: transform .55s cubic-bezier(.075, .5, 0, 1);
}
body {
  font-family: 'Orbitron', sans-serif;
  background-color:var(--background);
  color: var(--text);
}
//[data-scroll-container] {
//  .has-scroll-smooth & {
//    overflow-y: clip !important;
//  }
//}
.content {
  will-change: transform;
}
.c-scrollbar {
  position: fixed
}
section {
  height: 100vh;
  width: 100%;
  will-change: transform;
}
div[role='progressbar'] {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: #171717;
}
//html {
//  &.has-scroll-smooth {
//    overflow: hidden;
//  }
//}